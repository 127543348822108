.pac-container {
  z-index: 9999;
}

label.MuiInputLabel-filled.MuiInputLabel-shrink {
  transform: translate(12px, -6px) scale(0.75);
  background-color: #24262D;
}

.qrcode-box {
  background-color: white;
  color: black;
}

.qrcode-box>div:nth-child(1)>img {
  z-index: -5 !important;
}

.knowledge-base-list__icon span {
  display: flex;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 16px;
  width: 60px;
  height: 60px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  transition: border 1s ease-out;
  opacity: .3;
  transition: all .6s ease-out;
}

.knowledge-base-list .knowledge-base-list__holder:hover .knowledge-base-list__icon span {
  opacity: 1;
  border-color: white;
  box-shadow: 0 0 15px white;
}

div.ql-snow .ql-stroke {
  stroke: #ccc;
}

div.ql-snow .ql-picker .ql-picker-label {
  color: #ccc;
}

div.ql-toolbar.ql-snow {
  border-radius: 4px 4px 0 0;
  border-color: rgba(255, 255, 255, 0.4);
}

div.ql-container.ql-snow {
  border-radius: 0 0 4px 4px;
  border-color: rgba(255, 255, 255, 0.4);
}

.clear-indents p {
  margin: 0;
}

.clear-indents h1 {
  margin: 0;
}

.clear-indents h2 {
  margin: 0;
}

.clear-indents h3 {
  margin: 0;
}

.clear-indents h4 {
  margin: 0;
}

.clear-indents h5 {
  margin: 0;
}

.clear-indents h6 {
  margin: 0;
}

.clear-indents ul {
  margin: 0;
}

/* div.MuiDrawer-paper {
  overflow-y: visible;
} */

.googleplay {
  background-color: #3BCCFF;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
}

.amazonkdp {
  background-color: #ff9900;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
}

.smashwords {
  background-color: #4284BF;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
}

.kobowritinglife {
  background-color: #BA0010;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
}

.draft2digital {
  background-color: #EA472D;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
}

.appleitunesconnect {
  background-color: #7d7d7d;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
}

.barnesandnoble {
  background-color: #2a5934;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
}

.acx {
  background-color: #f49432;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
}

.acxbounty {
  background-color: #f49432;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
}

.findaway {
  background-color: #cb2c35;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
}